import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Paginate from "../components/paginate"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

export const data = graphql`
  query videoPostsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/video-production/" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const VideoProductionPage = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : `${currentPage - 1}`
  const nextPage = `${currentPage + 1}`

  return (
    <Layout>
      <SEO title="Video Production" />
      <Hero
        pageTitle="Video Production"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />

      <section
        id="result"
        className="section-1 showcase blog-grid filter-section projects"
      >
        <div className="overflow-holder">
          <div className="container">
            {isFirst && (
              <div className="container smaller">
                <div className="row text-center intro">
                  <div className="col-12">
                    <span className="pre-title">Our work in</span>
                    <h2>
                      Video{" "}
                      <span className="featured">
                        <span>Production</span>
                      </span>
                    </h2>
                    <p className="text-max-800">
                      Broadcast results of a study, spearhead a public
                      participation program, or market an airport or service.
                      Our mission is to ensure that the key points are readily
                      accessible, visually appealing, and easily understood by
                      audiences with little or no aviation experience.
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="row items">
              {data.allMarkdownRemark.edges.map(edge => {
                return (
                  <div
                    key={edge.node.id}
                    className="col-12 col-md-6 col-lg-4 item"
                  >
                    <div className="row card p-0 text-center">
                      <div className="image-over">
                        <Img
                          fluid={
                            edge.node.frontmatter.image.childImageSharp.fluid
                          }
                          alt="Lorem ipsum"
                        />
                      </div>
                      <div className="card-footer d-lg-flex align-items-center justify-content-center">
                        <Link
                          className="d-lg-flex align-items-center"
                          to={`/video-production${edge.node.fields.slug}`}
                        >
                          View Media
                        </Link>
                      </div>
                      <div className="card-caption col-12 p-0">
                        <div className="card-body">
                          <Link
                            to={`/video-production${edge.node.fields.slug}`}
                          >
                            <h4>{edge.node.frontmatter.title}</h4>
                            <p>{edge.node.excerpt}</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="row d-flex justify-content-center">
              <Paginate
                isFirst={isFirst}
                isLast={isLast}
                nextPage={nextPage}
                prevPage={prevPage}
                currentPage={currentPage}
                numPages={numPages}
                postType="/video-production/"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default VideoProductionPage
